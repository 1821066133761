<template>
    <div>
        <div class="vx-row">
            <div class="vx-col w-1/2">
                <vs-button
                    :color="showFilters ? '#666' : '#FFF'"
                    :text-color="showFilters ? '#FFF' : '#666'"
                    class="rounded-full"
                    @click="showFilters = !showFilters"
                >
                    <feather-icon icon="SlidersIcon" class="inline-block align-middle transform rotate-90 mr-2"></feather-icon>
                    Filtros de Búsqueda
                </vs-button>
            </div>

            <div class="vx-col w-1/2">
                <div
                    class="vs-con-loading__container w-1/6 inline-flex h-12"
                    id="button-with-loading"
                ></div>

                <!-- <div
                    class="w-1/3 inline-flex align-middle rounded-full float-right pt-2 pl-2"
                    style="background-color: #CCC; color: #666;"
                >
                    <feather-icon icon="SearchIcon" class="inline-block align-middle"></feather-icon>&nbsp;
                    <input
                        id="search-icon"
                        v-model="filterText"
                        type="text"
                        class="w-11/12 inline-block align-middle bg-transparent border-0 mb-2"
                        placeholder="¿Qué estás Buscando?"
                        autofocus
                    />
                </div> -->
            </div>
        </div>

        <!-- filtros de busqueda -->
        <filters v-if="showFilters" class="mt-10"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

import Filters from './Filters.vue';

export default Vue.extend({
    data() {
        return {
            showFilters : false,
            filterText  : '',
        }
    },

    watch : {
        showFilters( val ) {
            if( !val ) {
                this.$vs.loading();
                this.$store.dispatch( 'inventories/getAllInventories' )
                    .then( () => this.$vs.loading.close() );
            }
        },
        filterText( val ) {
            this.searchPromo( val );
        }
    },

    methods : {
        searchPromo( text='' ) {
            this.$vs.loading({
                color: 'info',
                container: "#button-with-loading",
                scale: 0.65
            });
            
            const filter = {
                filter : {
                    product : text,
                },
            };
            
            this.$store.dispatch( 'inventories/filterInventories', filter )
                .then( () => {
                    this.$vs.loading.close("#button-with-loading > .con-vs-loading");
                });
        },
    },

    components: {
        Filters,
    }
})
</script>