<template>
    <div>
        <vx-card>
            <div class="flex justify-between align-middle">
                <h2 class="text-info">Promociones Actuales</h2>

                <vs-button @click="findInventories()" color="transparent" text-color="gray">
                    <feather-icon icon="RefreshCwIcon" :class="{ 'animate-spin': loading }"></feather-icon>
                </vs-button>
            </div>

            <div class="flex mt-10 justify-between">
                <!-- eslint-disable -->
                <vs-select :label="this.$t('Items por página')" v-model="pagination.page_size" class="w-24">
                    <vs-select-item :key="index" :value="item" :text="item" v-for="item,index in maxItemsVals" />
                </vs-select>

                <div class="w-1/6 h-12 border-2 border-solid rounded-full" @click="$refs['products-search'].focus()">
                    <div class="flex h-full">
                        <feather-icon :icon="'SearchIcon'" class="mt-2 mb-2 ml-2" />
                        <input
                            class="h-full w-full border border-none mr-5"
                            ref="products-search"
                            v-model="pagination.name"
                        />
                    </div>
                </div>
            </div>
            
            <vs-table :data="promotions" :noDataText=" $t( 'Datos no disponibles' ) ">
                <template slot="thead">
                    <vs-th>SKU</vs-th>
                    <vs-th>Nombre</vs-th>
                    <vs-th>Precio Normal</vs-th>
                    <vs-th>Promoción</vs-th>
                    <vs-th></vs-th> <!-- porcentaje -->
                    <vs-th>Tienda</vs-th>
                    <vs-th class="w-10">Cant.</vs-th>
                    <vs-th></vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">
                        <!-- ID -->
                        <vs-td :data="data[indextr].product_sku">
                            <p class="text-primary">{{ data[indextr].product_sku }}</p>
                        </vs-td>

                        <!-- nombre -->
                        <vs-td :data="data[indextr].product">
                            {{ data[indextr].product }}
                        </vs-td>

                        <!-- precio normal -->
                        <vs-td :data="data[indextr].price">
                            {{ data[indextr].price | coin }}
                        </vs-td>

                        <!-- Precio de promoción -->
                        <vs-td :data="data[indextr].discount">
                            <vs-input
                                v-if="data[indextr].edit"
                                type="number"
                                v-model="data[indextr].discount"
                                @keyup="validatePromotionPrice( data[indextr].id, data[indextr].discount )"
                                @change="validatePromotionPrice( data[indextr].id, data[indextr].discount )"
                            />
                            <span v-else>{{ data[indextr].discount | coin }}</span>
                            <p v-if="data[indextr].edit && !!promoPriceError" class="text-danger">{{ promoPriceError }}</p>
                        </vs-td>

                        <!-- Porcentaje de descuento -->
                        <vs-td :data="data[indextr].discountPercentage">
                            <div
                                style="background: #dee2e5; color: #6f758b;"
                                class="w-16 p-1 rounded-lg text-center"
                            >
                                <!-- {{ data[indextr].discountPercentage }}% -->
                                {{ calculatePercentage( data[indextr].discount, data[indextr].price ) }}%
                            </div>
                        </vs-td>

                        <!-- tienda -->
                        <vs-td :data="data[indextr].shop">
                            {{ data[indextr].shop }}
                        </vs-td>

                        <!-- cantidad -->
                        <vs-td :data="data[indextr].quantity">
                            <!-- <vs-input
                                v-if="data[indextr].edit"
                                type="number"
                                v-model="data[indextr].quantity"
                                @keyup="validateQuantity( data[indextr].quantity )"
                                @change="validateQuantity( data[indextr].quantity )"
                            /> -->
                            <span>{{ data[indextr].quantity }}</span>
                            <!-- <p v-if="data[indextr].edit && !!quantityError" class="text-danger">{{ quantityError }}</p> -->
                        </vs-td>

                        <!-- editar, eliminar, guardar -->
                        <vs-td :data="data[indextr].id" class="w-48">
                            <!-- editar -->
                            <vs-button color="transparent" size="small" class="w-5" @click="editPromo( data[indextr].id )" :disabled="data[indextr].edit">
                                <img v-if="data[indextr].edit" :src="require(`@/assets/icons/EditBlueIcon.svg`)" class="w-4"/>
                                <img v-else :src="require(`@/assets/icons/EditGrayIcon.svg`)" class="w-4"/>
                            </vs-button>

                            <!-- eliminar -->
                            <vs-button color="transparent" size="small" class="w-5" @click="activeDeleteModal( data[indextr].id )" :disabled="data[indextr].edit">
                                <img v-if="data[indextr].id === selectedPromo.id" :src="require(`@/assets/icons/DeleteBlueIcon.svg`)" width="13px" />
                                <img v-else :src="require(`@/assets/icons/DeleteGrayIcon.svg`)" width="13px" />
                            </vs-button>

                            <!-- guardar -->
                            <vs-button v-if="editing" color="transparent" size="small" class="w-5" @click="savePromo( data[indextr].id )" :disabled="!data[indextr].edit">
                                <img v-if="data[indextr].edit" :src="require(`@/assets/icons/CheckGreenIcon.svg`)" class="w-4"/>
                                <img v-else :src="require(`@/assets/icons/CheckGrayIcon.svg`)" class="w-4"/>
                            </vs-button>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>

            <vs-pagination :total="pagination.total" v-model="pagination.current" class="mt-5"></vs-pagination>

        </vx-card>

        <!-- modal eliminar -->
        <vs-prompt
            @accept="deletePromo()"
            @close="closeDeleteDialog()"
            :active.sync="activePrompt"
            title="Eliminar"
            buttons-hidden
        >
            <div class="w-full">
                <vs-button  class="float-right" @click="closeDeleteDialog()" color="transparent" text-color="#666">
                    <feather-icon icon='XIcon'  />
                </vs-button>
            </div>
            
            <div class="w-full flex justify-center mb-5">
                <img :src="require(`@/assets/images/elements/Trash.svg`)" alt="Delete Icon" width="128px" />
            </div>
            
            <div class="text-center text-gray-400">
                <h3 class="mb-2">Eliminar promoción seleccionada</h3>

                <p>¿Estás seguro que deseas eliminar definitivamente la promoción seleccionada?</p>

                <p><vs-button class="mt-5 rounded-full" @click="deletePromo()">Eliminar Definitivamente</vs-button></p>
                <p><vs-button  class="mt-2 text-primary" @click="closeDeleteDialog()" color="#FFF">Cancelar</vs-button></p>
            </div>
        </vs-prompt>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({

    data() {
        return {
            editing         : false,
            promoPriceError : '',
            quantityError   : '',
            activePrompt    : false,
            selectedPromo   : {},
            words           : 'unexpected error',
            loading         : false,
            pagination      : { total: 1, current: 1, page_size: 100, name: '' },
            timeout         : null,
            maxItemsVals    : [5,10,15,20,25,30,35,40,45,50,55,65,75,85,95,100],
        }
    },

    created() {
        this.findInventories();
    },

    watch: {
        'pagination.current'(){ this.findInventories(); },
        'pagination.page_size'(){ this.findInventories(); },
        'pagination.name'() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.findInventories();
            }, 1000);
        },
    },

    methods: {
        editPromo( promoId ) {
            let promo = this.promotions.find( promo => promo.id === promoId );
            promo.edit = true;

            this.editing = true;
        },

        validatePromotionPrice( promoId, promoPrice ) {
            let promo = this.promotions.find( promo => promo.id === promoId );

            if( promoPrice > promo.price ) {
                this.promoPriceError = 'El precio de promoción no puede ser mayor que el precio normal';
                return false;

            } else if( promoPrice < 0 ) {
                this.promoPriceError = 'Ingrese un número valido de promoción';
                return false;
            }
            
            this.promoPriceError = '';

            return true;
        },

        validateQuantity( quantity ) {
            if( quantity < 1 ) {
                this.quantityError = 'La cantidad no puede ser menor o igual a cero';
                return false;
            }
            
            this.quantityError = '';

            return true;
        },

        savePromo( promoId ) {
            let promo = this.promotions.find( promo => promo.id === promoId );

            const isValidPromoPrice = this.validatePromotionPrice( promo.id, promo.promotionPrice );
            const isValidQuantity   = this.validateQuantity( promo.quantity );

            if( !isValidPromoPrice || !isValidQuantity ) {
                this.$vs.notify({
                    title: 'Valide los campos antes de continuar',
                    text: '',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning',
                });
                return;
            }

            const promoPayload = {
                id         : promo.id,
                discount   : parseFloat( promo.discount ),
                product_id : promo.product_id,
                shop_id    : promo.shop_id,
                price      : promo.price,
                quantity   : promo.quantity,
            }

            this.$vs.loading();
            this.$store.dispatch( 'inventories/updateInventorie', promoPayload )
                .then( res => {
                    this.$vs.loading.close();

                    if( res )  {
                        promo.edit = false;
                        this.editing = false;
                    }
                });
        },

        activeDeleteModal( promoId ) {
            const promo = this.promotions.find( promo => promo.id === promoId );

            this.activePrompt = true;
            this.selectedPromo = promo;
        },

        deletePromo() {
            this.$vs.loading();
            this.$store.dispatch( 'inventories/deleteProduct', this.selectedPromo.id )
                .then( res => {
                    this.$vs.loading.close();

                    if( res ) {
                        this.selectedPromo = {};
                        this.activePrompt = false;
                    }
                });
        },

        closeDeleteDialog() {
            this.selectedPromo = {};
            this.activePrompt = false;
        },

        calculatePercentage( percentegePrice=0, normalPrice=0 ) {
            const percentage = ((percentegePrice-normalPrice) /normalPrice) *100
            return Math.round( percentage );
        },

        refreshProductsList() {
            this.loading = true;
             this.$store.dispatch( 'inventories/getAllInventories' ).then( () => this.loading = false );
        },

        async findInventories() {
            this.loading = true
            const payload = {
                page      : this.pagination.current,
                page_size : this.pagination.page_size,
                name      : this.pagination.name,
            };

            const results = await this.$store.dispatch('inventories/findPromotions', payload);

            if( !results ) this.loading = false;
            else {
                this.loading = false;
                this.pagination.total = results.data.total_pages;
                
            }

            
        }
    },
    
    computed: {
        promotions() {
            const promos         = this.$store.state.inventories.inventories;            
            // const promosFiltered = promos.filter( promo => promo.price !== promo.discount && promo.discount !== 0 );
            // const promosMapped   = promosFiltered.map( promo => {
            //     const ob = Object.assign( {}, promo );

            //     ob.edit = false;

            //     return ob;
            // });

            // return promosMapped;
            return promos;
        }
    }
})
</script>

<style lang="scss">
.vs-dialog-header {
    display: none !important;
}
</style>
