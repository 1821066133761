<template>
    <div>
        <!-- filtros de busqueda -->
        <Header class="mt-10"/>
        <!-- listado de promociones -->
        <promotions-list class="mt-10"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

import Header from './Header.vue';
import PromotionsList from './PromotionsList.vue';

export default Vue.extend({
    
    created() {
        this.$store.dispatch( 'updatePageTitle', {
            title    : 'Promociones',
            subtitle : 'Conoce las promociones actuales en tu negocio',
        })
    },

    components: {
        Header,
        PromotionsList,
    }

})
</script>